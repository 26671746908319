import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import PageLayout from "../components/pagelayout";
import SEO from "../components/seo";
import Img from "gatsby-image";

export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        author
        published
        image {
          sharp: childImageSharp {
            fixed(width: 75, height: 75, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      body
    }
  }
`;

const PostTemplate = ({ data: { mdx: post } }) => (
  <PageLayout>
    <SEO title={post.frontmatter.title} />
    <div className="single-post-wrapper">
      <div className="post-title-wrapper">
        <Img
          className="single-post-image"
          fixed={post.frontmatter.image.sharp.fixed}
          title={post.frontmatter.title}
        />
        <div>
          <h2 className="post-title">{post.frontmatter.title}</h2>
          <br />
          <h5 className="post-publication">{post.frontmatter.published}</h5>
        </div>
      </div>
      <MDXRenderer>{post.body}</MDXRenderer>
    </div>
  </PageLayout>
);

export default PostTemplate;
